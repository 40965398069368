<template>
    <div class="columns is-centered">
        <div class="column is-half-desktop is-full-touch is-narrow max-width">
            <form @submit.prevent="fetch">
                <div class="box p-3">
                    <div class="title has-text-centered has-text-dark is-5">
                        {{ i18n('Registro') }}
                    </div>
                    <div class="bloq">
                        <label for="name">Nombre:</label>
                        <input class="input is-info"
                               type="text" id="name" v-model="form.name" required>
                    </div>
                    <div class="bloq">
                        <label for="surname">Apellido:</label>
                        <input class="input is-info"
                               type="text" id="surname" v-model="form.surname" required>
                    </div>
                    <div class="bloq">
                        <label for="phone">Teléfono:</label>
                        <input class="input is-info"
                               type="tel" id="phone" v-model="form.phone" required>
                    </div>
                    <div class="bloq">
                        <label for="birthdate">Fecha de nacimiento:</label>
                        <input class="input is-info"
                               type="date" id="birthday" v-model="form.birthday">
                    </div>
                    <div class="bloq">
                        <label for="email">{{ i18n("Email") }}:</label>
                        <input class="input is-info"
                               type="email" id="email" v-model="form.email" required>
                    </div>
                    <div class="bloq">
                        <label for="password">{{ i18n("Password") }}:</label>
                        <input class="input is-info"
                               name="password"
                               type="password" id="password" v-model="form.password" required>
                    </div>
                    <div class="bloq">
                        <label for="password_confirmation">
                            {{ i18n("Repeat Password") }}:
                        </label>
                        <input class="input is-info"
                               name="password_confirmation"
                               type="password" id="password_confirmation"
                               v-model="form.password_confirmation" required>
                    </div>
                    <div class="bloq">
                        <div class="field">
                            <label class="label"> Selecciona una pregunta</label>
                            <select class="select" v-model="selectedQuestion">
                                <option v-for="question in questions"
                                        :key="question.id"
                                        :value="question.id">
                                    {{ question.question }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-if="selectedQuestion" class="bloq">
                        <label for="answer">Tu respuesta:</label>
                        <input class="input is-info"
                               name="answer"
                               type="text" id="answer" v-model="form.answer" required>
                    </div>
                    <div class="bloq">
                        <div class="field">
                            <label class="checkbox" for="policies">Políticas de privacidad:
                                <input type="checkbox" id="policies"
                                       v-model="form.policies" required>
                            </label>
                        </div>
                    </div>
                    <div class="bloq">
                        <button class="button is-primary is-fullwidth" type="submit">
                            Enviar
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="columns is-centered" id="continer-register">
            <a class="button butt control field btn-goback" :href="urlRegister">
                <fa icon="arrow-left"/>
            </a>
            <div v-if="isBotmanView" ref="botmanWidgetRoot" id="botmanWidget" class="chat-widget"/>
        </div>
    </div>
</template>
<script>

import { mapGetters, mapMutations, mapState } from 'vuex';
import { FontAwesomeIcon as Fa } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowLeft);

// eslint-disable-next-line vue/one-component-per-file
export default {
    name: 'Register',
    components: { Fa },
    inject: ['route', 'routerErrorHandler', 'toastr', 'http', 'i18n'],
    data: () => ({
        ready: false,
        questions: [],
        form: {
            name: '',
            surname: '',
            phone: '',
            password: '',
            email: '',
            policies: false,
            securityQuestion: '',
            answer: '',
            birthday: '',
        },
        selectedQuestionId: null,
        selectedQuestion: null,
        showForm: false,
        isBotmanView: false,
        urlRegister: '/login',
    }),
    computed: {
        ...mapState(['meta']),
        ...mapGetters(['isWebview']),
    },

    mounted() {
        this.getQuestions();
    },
    methods: {
        ...mapMutations('auth', ['login']),
        ...mapMutations('layout', ['home']),
        init() {
            setTimeout(() => {
                this.login();
                this.home(true);
            }, 500);
        },
        getQuestions() {
            this.http.get(this.route('auth.getQuestion'))
                .then(({ data }) => {
                    this.questions = data.questions;
                })
                .catch(this.errorHandler);
        },
        fetch() {
            this.http.post(this.route('register.store'), {
                name: this.form.name,
                surname: this.form.surname,
                phone: this.form.phone,
                birthday: this.form.birthday,
                email: this.form.email,
                password: this.form.password,
                password_confirmation: this.form.password_confirmation,
                security_question: this.selectedQuestion,
                answer: this.form.answer,
                is_active: this.form.policies,
            }).then(({ data }) => {
                this.toastr.success(data.message);
                // Inside a navigation guard or a Vue component
                window.location.href = ('/login');
            }).catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss" scoped>
//@import url('https://cdn.jsdelivr.net/npm/botman-web-widget@0/build/assets/css/chat.min.css');
@import '../../../sass/enso.scss';

.chat-messages {
    max-height: 200px;
    overflow-y: auto;
}

.btn-goback{
    position: fixed;
    top: 20px;
    left: 20px;
    background-color: $bluish;
    color: $secondary;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px;
}

.max-width {
    max-width: 400px;
}

.bloq{
    padding-bottom: 10px;
}
.select-question{
    width: 100%;
}
</style>
